export const IconActive = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 7C14 9.20914 12.2091 11 10 11C7.79086 11 6 9.20914 6 7C6 4.79086 7.79086 3 10 3C12.2091 3 14 4.79086 14 7ZM12 7C12 5.89543 11.1046 5 10 5C8.89543 5 8 5.89543 8 7C8 8.10457 8.89543 9 10 9C11.1046 9 12 8.10457 12 7ZM21.6585 4.25001C21.2441 3.88604 20.6132 3.92631 20.2485 4.34001L18.3785 6.49001L17.7485 5.78001C17.3812 5.3658 16.7477 5.32775 16.3335 5.69501C15.9193 6.06228 15.8812 6.6958 16.2485 7.11001L17.6385 8.67001C17.8299 8.88209 18.1028 9.00219 18.3885 9.00001C18.6722 8.9968 18.9412 8.8732 19.1285 8.66001L21.7385 5.66001C22.105 5.24829 22.0692 4.61764 21.6585 4.25001ZM3 20C3 16.134 6.13401 13 10 13C13.866 13 17 16.134 17 20C17 20.5523 16.5523 21 16 21C15.4477 21 15 20.5523 15 20C15 17.2386 12.7614 15 10 15C7.23858 15 5 17.2386 5 20C5 20.5523 4.55228 21 4 21C3.44771 21 3 20.5523 3 20Z"
      fill="#0170F4"
    />
  </svg>
)
