export const IconNote = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.21118 7C7.21118 7.55228 7.6589 8 8.21118 8H16.2112C16.7635 8 17.2112 7.55228 17.2112 7V3C17.2112 2.44772 16.7635 2 16.2112 2H8.21118C7.6589 2 7.21118 2.44772 7.21118 3V7ZM18.2112 7V4C19.868 4 21.2112 5.34315 21.2112 7V19C21.2112 20.6568 19.868 22 18.2112 22H6.21118C4.55433 22 3.21118 20.6568 3.21118 19V7C3.21118 5.34315 4.55433 4 6.21118 4V7C6.21118 8.10457 7.10661 9 8.21118 9H16.2112C17.3158 9 18.2112 8.10457 18.2112 7Z"
      fill="#FFCF74"
    />
  </svg>
)
