export const IconSelectionFile = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="48" cy="48" r="48" fill="#F0F7FF" />
    <path
      d="M57.4292 30.9804L66.7225 41.2304C67.0073 41.5437 67.1655 41.9516 67.1666 42.375V60.3125L67.1666 60.3131C67.1382 62.7 65.1802 64.6118 62.7933 64.5833H44.2066L44.206 64.5833C41.8192 64.6115 39.8614 62.6993 39.8333 60.3125V34.6875L39.8333 34.6869C39.8618 32.3 41.8198 30.3882 44.2067 30.4167H56.165C56.647 30.4178 57.1062 30.6226 57.4292 30.9804ZM61.5949 40.6668L56.9141 35.5418L56.9141 39.2147L56.9125 39.2383C56.8676 39.9823 57.4343 40.6219 58.1783 40.6668H61.5949ZM62.7933 61.1667H44.2067C43.7075 61.1957 43.2792 60.8146 43.2502 60.3154L43.25 60.3125V34.6875C43.2775 34.1882 43.7045 33.8057 44.2038 33.8332L44.2067 33.8333H53.5V39.2146C53.4426 41.8341 55.5105 44.0088 58.1296 44.0833H63.75V60.3125C63.7225 60.8118 63.2955 61.1943 62.7962 61.1668L62.7933 61.1667ZM48.375 50.9167H53.5C54.4435 50.9167 55.2083 50.1518 55.2083 49.2083C55.2083 48.2648 54.4435 47.5 53.5 47.5H48.375C47.4315 47.5 46.6666 48.2648 46.6666 49.2083C46.6666 50.1518 47.4315 50.9167 48.375 50.9167ZM48.375 54.3333C47.4315 54.3333 46.6666 55.0982 46.6666 56.0417C46.6666 56.9852 47.4315 57.75 48.375 57.75H58.625C59.5685 57.75 60.3333 56.9852 60.3333 56.0417C60.3333 55.0982 59.5685 54.3333 58.625 54.3333H48.375Z"
      fill="#0170F4"
    />
    <path
      d="M32.0937 52.2499C31.7819 52.2489 31.4845 52.1186 31.2724 51.8899L25.8049 46.0737H25.8049C25.3793 45.6201 25.402 44.9074 25.8556 44.4818C26.3091 44.0562 27.0218 44.0789 27.4474 44.5324L32.0824 49.4712L41.5437 39.1212V39.1212C41.9327 38.6367 42.6408 38.5594 43.1253 38.9484C43.6097 39.3375 43.6871 40.0456 43.298 40.53C43.2702 40.5646 43.2404 40.5976 43.2087 40.6287L32.9262 51.8787C32.7161 52.1115 32.4185 52.246 32.1049 52.2499L32.0937 52.2499Z"
      fill="#FF4891"
    />
    <path
      d="M32.0937 61.2499C31.7819 61.2489 31.4845 61.1186 31.2724 60.8899L25.8049 55.0737H25.8049C25.3793 54.6201 25.402 53.9074 25.8556 53.4818C26.3091 53.0562 27.0218 53.0789 27.4474 53.5324L32.0824 58.4712L41.5437 48.1212V48.1212C41.9327 47.6367 42.6408 47.5594 43.1253 47.9484C43.6097 48.3375 43.6871 49.0456 43.298 49.53C43.2702 49.5646 43.2404 49.5976 43.2087 49.6287L32.9262 60.8787C32.7161 61.1115 32.4185 61.246 32.1049 61.2499L32.0937 61.2499Z"
      fill="#FF4891"
    />
  </svg>
)
