export const IconRequests = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} width="212" height="212" viewBox="0 0 212 212" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M136.738 41.3453C136.738 41.3453 114.062 70.068 77.7808 52.9351C41.4994 35.8022 5.21796 82.1618 36.4603 110.381C67.7026 138.599 37.1297 154.106 58.3545 171.4C82.4779 191.056 103.48 151.197 122.629 160.268C164.89 180.286 196.199 172.361 189.145 149.182C176.761 108.493 160.926 105.846 173.019 84.6814C185.113 63.5172 158.422 19.5117 136.738 41.3453Z"
      fill="#F0F7FF"
    />
    <path
      d="M124.392 120.785L122.601 130.403L114.068 176.123L98.6977 173.253L108.553 120.438L109.022 117.921L124.392 120.785Z"
      fill="#4D94FF"
    />
    <path
      d="M162.247 97.784L156.947 126.198C156.931 126.282 156.899 126.361 156.853 126.432C156.807 126.503 156.747 126.564 156.677 126.612C156.607 126.66 156.529 126.694 156.446 126.711C156.363 126.729 156.277 126.73 156.194 126.714L81.7329 112.826L90.497 65.8365L147.04 76.3825C157.288 78.2935 164.095 87.8733 162.247 97.784Z"
      fill="#0170F4"
    />
    <path
      d="M106.401 88.2485L101.142 116.442L62.0575 109.153L67.3159 80.9588C69.252 70.5782 79.5713 63.7947 90.3641 65.8077C101.157 67.8206 108.337 77.8677 106.401 88.2485Z"
      fill="#4D94FF"
    />
    <path
      d="M124.597 101.428C121.717 100.891 119.816 98.0917 120.359 95.1847C120.901 92.2778 123.682 90.3511 126.563 90.8887C127.481 91.0611 128.337 91.4737 129.044 92.0844C129.751 92.6951 130.283 93.4821 130.587 94.3655L166.97 101.151L165.987 106.421L129.605 99.6354C129.002 100.35 128.222 100.892 127.342 101.207C126.463 101.522 125.515 101.598 124.597 101.428Z"
      fill="#005CE6"
    />
    <path
      d="M105.104 88.7666L99.9809 116.236L63.1615 109.369L68.2852 81.8993C70.1715 71.7856 79.9433 65.1237 90.1106 67.0201C100.278 68.9164 106.991 78.6528 105.104 88.7666Z"
      fill="#005CE6"
    />
    <path
      d="M125.628 98.766C127.109 98.766 128.309 97.5659 128.309 96.0856C128.309 94.6052 127.109 93.4052 125.628 93.4052C124.148 93.4052 122.948 94.6052 122.948 96.0856C122.948 97.5659 124.148 98.766 125.628 98.766Z"
      fill="white"
    />
    <path
      d="M167.39 101.133L164.176 118.364C164.163 118.438 164.12 118.504 164.058 118.546C163.995 118.589 163.919 118.605 163.844 118.591L155.396 117.016C155.382 117.013 155.369 117.004 155.36 116.992C155.352 116.98 155.349 116.965 155.351 116.95L158.607 99.4943C158.61 99.4797 158.618 99.4667 158.631 99.4583C158.643 99.4499 158.658 99.4467 158.673 99.4494L167.345 101.067C167.36 101.07 167.373 101.078 167.381 101.09C167.39 101.103 167.393 101.118 167.39 101.133Z"
      fill="#4D94FF"
    />
    <path
      d="M75.3459 99.5113C75.7017 102.128 78.5734 105.239 78.5734 105.239C74.1975 105.549 71.8277 103.074 70.808 101.571C69.5472 101.935 68.2515 102.165 66.9424 102.257C57.9588 102.893 50.2252 97.0284 49.6667 89.15C49.1082 81.2717 55.9373 74.3742 64.9209 73.7376C73.9045 73.101 81.6375 78.9657 82.1959 86.8442C82.5591 91.9688 79.7939 96.6791 75.3459 99.5113Z"
      fill="white"
    />
    <path
      d="M101.18 116.46C100.773 117.489 93.334 148.165 69.2595 143.675C42.8602 139.206 61.9554 109.144 61.9554 109.144L101.18 116.46Z"
      fill="#0170F4"
    />
    <path
      d="M65.9311 90.6998C67.1647 90.6998 68.1647 89.6998 68.1647 88.4662C68.1647 87.2326 67.1647 86.2325 65.9311 86.2325C64.6975 86.2325 63.6974 87.2326 63.6974 88.4662C63.6974 89.6998 64.6975 90.6998 65.9311 90.6998Z"
      fill="#4D94FF"
    />
    <path
      d="M55.9315 89.7258C57.1726 89.7258 58.1786 88.7197 58.1786 87.4787C58.1786 86.2376 57.1726 85.2316 55.9315 85.2316C54.6905 85.2316 53.6844 86.2376 53.6844 87.4787C53.6844 88.7197 54.6905 89.7258 55.9315 89.7258Z"
      fill="#4D94FF"
    />
    <path
      d="M75.8349 91.6517C77.076 91.6517 78.082 90.6456 78.082 89.4046C78.082 88.1635 77.076 87.1575 75.8349 87.1575C74.5939 87.1575 73.5878 88.1635 73.5878 89.4046C73.5878 90.6456 74.5939 91.6517 75.8349 91.6517Z"
      fill="#4D94FF"
    />
    <path d="M124.392 120.785L122.601 130.403L108.553 120.438L109.022 117.921L124.392 120.785Z" fill="#0170F4" />
  </svg>
)
