export const IconCalendarCheck = ({ className }: { className?: string }) => (
  <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" className={`fill-current ${className}`}>
    <path
      d="M86.831 57.167a4.001 4.001 0 010 5.664l-24 24a4 4 0 01-5.664 0l-12-12a4.006 4.006 0 015.664-5.664L60 78.343l21.168-21.176a4.002 4.002 0 015.664 0z"
      fill="#E1E6EA"
    />
    <path
      d="M28 0a4 4 0 014 4v4h64V4a4 4 0 118 0v4h8a16.002 16.002 0 0116 16v88c0 4.243-1.686 8.313-4.686 11.314A16.004 16.004 0 01112 128H16a16.002 16.002 0 01-16-16V24A16 16 0 0116 8h8V4a4 4 0 014-4zM8 32v80a8 8 0 008 8h96a8 8 0 008-8V32H8z"
      fill="#E1E6EA"
    />
  </svg>
)
