export const IconPersonPlus = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 256 263" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M96 117.333C119.564 117.333 138.667 98.2308 138.667 74.6667C138.667 51.1025 119.564 32 96 32C72.4359 32 53.3334 51.1025 53.3334 74.6667C53.3334 98.2308 72.4359 117.333 96 117.333ZM181.333 138.667C199.006 138.667 213.333 124.34 213.333 106.667C213.333 88.9936 199.006 74.6667 181.333 74.6667C163.66 74.6667 149.333 88.9936 149.333 106.667C149.333 124.34 163.66 138.667 181.333 138.667ZM234.667 202.667C234.666 208.558 229.891 213.333 224 213.333L170.667 213.333C170.667 219.224 165.891 224 160 224H31.9999C26.1089 224 21.3333 219.224 21.3333 213.333C21.3597 193.616 29.1842 174.709 43.0993 160.74C72.2014 131.523 119.478 131.431 148.694 160.533C158.026 153.312 169.489 149.389 181.288 149.379C210.743 149.354 234.642 173.212 234.667 202.667Z"
      fill="#E1E6EA"
    />
    <path
      d="M233.083 203.417H205.583V175.917C205.583 173.385 203.531 171.333 201 171.333C198.469 171.333 196.417 173.385 196.417 175.917V203.417H168.917C166.385 203.417 164.333 205.469 164.333 208C164.333 210.531 166.385 212.583 168.917 212.583H196.417V240.083C196.417 242.615 198.469 244.667 201 244.667C203.531 244.667 205.583 242.615 205.583 240.083V212.583H233.083C235.615 212.583 237.667 210.531 237.667 208C237.667 205.469 235.615 203.417 233.083 203.417Z"
      fill="#0170F4"
    />
  </svg>
)
