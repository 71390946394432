export const IconOffline = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 36 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5998 0H4.3998C3.97546 0 3.56849 0.168571 3.26843 0.468629C2.96838 0.768687 2.7998 1.17565 2.7998 1.6V14.4C2.7998 14.8243 2.96838 15.2313 3.26843 15.5314C3.56849 15.8314 3.97546 16 4.3998 16H11.5998C12.0242 16 12.4311 15.8314 12.7312 15.5314C13.0312 15.2313 13.1998 14.8243 13.1998 14.4V1.6C13.1998 1.17565 13.0312 0.768687 12.7312 0.468629C12.4311 0.168571 12.0242 0 11.5998 0V0ZM4.3998 14.4V1.6H11.5998V14.4H4.3998Z"
      fill="#BABEC3"
    />
    <path
      d="M8 13.8C7.73478 13.8 7.48043 13.6947 7.29289 13.5072C7.10536 13.3196 7 13.0653 7 12.8C7 12.5348 7.10536 12.2805 7.29289 12.0929C7.48043 11.9054 7.73478 11.8 8 11.8C8.26522 11.8 8.51957 11.9054 8.70711 12.0929C8.89464 12.2805 9 12.5348 9 12.8C9 13.0653 8.89464 13.3196 8.70711 13.5072C8.51957 13.6947 8.26522 13.8 8 13.8Z"
      fill="#BABEC3"
    />
    <path
      d="M32.3998 6.068C32.3031 5.62148 32.138 5.19259 31.9103 4.7965L34.9998 1.707L34.2933 1L20.9998 14.2935L21.7068 15L24.2068 12.5H31.7498C32.5554 12.5008 33.3326 12.2023 33.9305 11.6624C34.5284 11.1225 34.9043 10.3796 34.9853 9.57812C35.0663 8.77661 34.8466 7.97359 34.3688 7.32495C33.891 6.67632 33.1893 6.22835 32.3998 6.068ZM31.7498 11.5H25.2068L31.1708 5.536C31.3244 5.86333 31.4257 6.21275 31.4708 6.5715L31.5203 6.9775L31.9278 7.0095C32.5083 7.0537 33.0491 7.32133 33.4363 7.75609C33.8235 8.19085 34.0269 8.75884 34.0039 9.34057C33.9808 9.9223 33.733 10.4724 33.3126 10.8752C32.8922 11.2779 32.332 11.5019 31.7498 11.5ZM22.1478 11.7245L22.8638 11.009C22.5068 10.7314 22.2422 10.352 22.1049 9.92105C21.9677 9.49006 21.9642 9.0276 22.095 8.5946C22.2258 8.1616 22.4847 7.77838 22.8375 7.49542C23.1904 7.21247 23.6208 7.04305 24.0718 7.0095L24.4798 6.9775L24.5293 6.5715C24.6003 5.98851 24.8171 5.43283 25.1597 4.95582C25.5023 4.47881 25.9597 4.09586 26.4894 3.84239C27.0192 3.58891 27.6043 3.47308 28.1907 3.50561C28.7771 3.53815 29.3458 3.71798 29.8443 4.0285L30.5658 3.307C29.957 2.8826 29.2524 2.61563 28.5152 2.52996C27.778 2.44429 27.031 2.54258 26.3411 2.81605C25.6511 3.08952 25.0396 3.52965 24.5613 4.09712C24.0829 4.66459 23.7526 5.34171 23.5998 6.068C23.0025 6.18908 22.4514 6.47594 22.0095 6.89572C21.5676 7.3155 21.2529 7.85123 21.1014 8.44156C20.9499 9.0319 20.9677 9.65298 21.1527 10.2337C21.3378 10.8144 21.6827 11.3312 22.1478 11.725V11.7245Z"
      fill="#BABEC3"
    />
    <line x1="13" y1="7.5" x2="21" y2="7.5" stroke="#BABEC3" />
  </svg>
)
