import { Auth } from 'aws-amplify'
import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { Redirect, Route } from 'react-router-dom'
import { PrivateRoute } from 'src/components/PrivateRoute'
import { PageFadeAnimation } from 'src/components/animations'
import { useCurrentCall } from 'src/hooks/useCurrentValues'
import { DrawerProvider } from 'src/hooks/useDrawer'
import { PopupModalProvider } from 'src/hooks/usePopupModal'
import { SocketProvider } from 'src/hooks/useSocket'
import i18n from 'src/services/i18n'
import { DailyProvider } from '@daily-co/daily-react'
import { IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import loadable from '@loadable/component'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { CallOverlay } from './components/overlays/CallOverlay'
import './custom.css'

const PageEmailConfirmation = loadable(() => import('./pages/Login/PageEmailConfirmation'))
const PagePasswordNew = loadable(() => import('./pages/Login/PagePasswordNew'))
const PagePasswordReset = loadable(() => import('./pages/Login/PagePasswordReset'))
const AppDashboard = loadable(() => import('./AppDashboard'))
const AppWorkspace = loadable(() => import('./AppWorkspace'))
const PageLogin = loadable(() => import('./pages/Login/PageLogin'))
const PageRegister = loadable(() => import('./pages/Login/PageRegister'))
const PageDisclaimer = loadable(() => import('./pages/PageDisclaimer'))
const PageDisclaimerApp = loadable(() => import('./pages/PageDisclaimerApp'))

declare global {
  interface Window {
    Chargebee: any
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
    },
  },
})

const App: React.FC = () => {
  const { callObject } = useCurrentCall()

  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <SocketProvider>
          <DailyProvider callObject={callObject}>
            <PopupModalProvider>
              <DrawerProvider>
                <CallOverlay>
                  <IonReactRouter>
                    <IonRouterOutlet basePath="/" mode="md" animation={PageFadeAnimation}>
                      <Route path="/login" exact={true} render={() => <PageLogin />} />
                      <Route path="/sign-up" exact={true} render={() => <PageRegister />} />
                      <Route path="/password/reset" exact={true} render={() => <PagePasswordReset />} />
                      <Route path="/password/new/:email" exact={true} render={() => <PagePasswordNew />} />
                      <Route path="/email-confirmation/:email" exact={true} render={() => <PageEmailConfirmation />} />
                      <Route path="/disclaimer" exact={true} render={() => <PageDisclaimer />} />
                      <Route path="/disclaimerApp" exact={true} render={() => <PageDisclaimerApp />} />
                      <PrivateRoute strict={true} path="/workspace" component={AppWorkspace} />
                      <PrivateRoute strict={true} path="/dashboard" component={AppDashboard} />
                      <Redirect from="/" to="/dashboard" exact={true} />
                    </IonRouterOutlet>
                  </IonReactRouter>
                </CallOverlay>
              </DrawerProvider>
            </PopupModalProvider>
          </DailyProvider>
        </SocketProvider>
      </I18nextProvider>
    </QueryClientProvider>
  )
}
export default App
