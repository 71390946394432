export const IconPersonImport = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 256 263" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M96 117.333C119.564 117.333 138.667 98.2308 138.667 74.6667C138.667 51.1025 119.564 32 96 32C72.4359 32 53.3334 51.1025 53.3334 74.6667C53.3334 98.2308 72.4359 117.333 96 117.333ZM181.333 138.667C199.006 138.667 213.333 124.34 213.333 106.667C213.333 88.9936 199.006 74.6667 181.333 74.6667C163.66 74.6667 149.333 88.9936 149.333 106.667C149.333 124.34 163.66 138.667 181.333 138.667ZM234.667 202.667C234.666 208.558 229.891 213.333 224 213.333L170.667 213.333C170.667 219.224 165.891 224 160 224H31.9999C26.1089 224 21.3333 219.224 21.3333 213.333C21.3597 193.616 29.1842 174.709 43.0993 160.74C72.2014 131.523 119.478 131.431 148.694 160.533C158.026 153.312 169.489 149.389 181.288 149.379C210.743 149.354 234.642 173.212 234.667 202.667Z"
      fill="#E1E6EA"
    />
    <path
      d="M96 117.333C119.564 117.333 138.667 98.2308 138.667 74.6667C138.667 51.1025 119.564 32 96 32C72.4359 32 53.3334 51.1025 53.3334 74.6667C53.3334 98.2308 72.4359 117.333 96 117.333ZM181.333 138.667C199.006 138.667 213.333 124.34 213.333 106.667C213.333 88.9936 199.006 74.6667 181.333 74.6667C163.66 74.6667 149.333 88.9936 149.333 106.667C149.333 124.34 163.66 138.667 181.333 138.667ZM234.667 202.667C234.666 208.558 229.891 213.333 224 213.333L170.667 213.333C170.667 219.224 165.891 224 160 224H31.9999C26.1089 224 21.3333 219.224 21.3333 213.333C21.3597 193.616 29.1842 174.709 43.0993 160.74C72.2014 131.523 119.478 131.431 148.694 160.533C158.026 153.312 169.489 149.389 181.288 149.379C210.743 149.354 234.642 173.212 234.667 202.667Z"
      fill="#E1E6EA"
    />
    <path
      d="M169.977 201.766L169.977 201.766C171.593 203.715 174.482 203.985 176.431 202.37C176.434 202.367 176.437 202.365 176.44 202.362L196.423 185.725V240.083C196.423 242.614 198.475 244.666 201.006 244.666C203.538 244.666 205.59 242.614 205.59 240.083V185.725L225.573 202.362V202.362C227.522 203.982 230.415 203.716 232.035 201.766C233.656 199.817 233.389 196.924 231.44 195.304L203.94 172.387L203.252 171.975L202.656 171.654V171.654C201.594 171.244 200.418 171.244 199.356 171.654L198.76 171.975L198.073 172.387L170.573 195.304L170.573 195.304C168.624 196.92 168.354 199.809 169.97 201.758C169.972 201.761 169.975 201.764 169.977 201.766L169.977 201.766Z"
      fill="#0170F4"
    />
  </svg>
)
