export const IconDoubleArrow = ({ className }: { className: string }) => (
  <svg className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0002 20.0041C11.7344 20.0056 11.4789 19.9013 11.2902 19.7141L7.29019 15.7141C6.89806 15.322 6.89806 14.6862 7.29019 14.2941C7.68231 13.902 8.31807 13.902 8.71019 14.2941L12.0002 17.6041L15.3002 14.4241V14.4241C15.6695 14.0134 16.3018 13.9799 16.7124 14.3492C17.1231 14.7185 17.1566 15.3508 16.7873 15.7615C16.7542 15.7983 16.7184 15.8326 16.6802 15.8641L12.6802 19.7241C12.4973 19.9004 12.2542 20.0005 12.0002 20.0041L12.0002 20.0041Z"
      fill="#D1D4D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0436 4.00002C12.3094 3.99848 12.5648 4.10283 12.7536 4.29002L16.7536 8.29002C17.1457 8.68214 17.1457 9.3179 16.7536 9.71002C16.3614 10.1021 15.7257 10.1021 15.3336 9.71002L12.0436 6.40002L8.74355 9.58002C8.37425 9.99066 7.74197 10.0242 7.33132 9.65487C6.92067 9.28557 6.88716 8.6533 7.25646 8.24264C7.28957 8.20583 7.32537 8.17153 7.36355 8.14002L11.3636 4.28002C11.5464 4.1037 11.7895 4.00359 12.0436 4.00002Z"
      fill="#D1D4D8"
    />
  </svg>
)
