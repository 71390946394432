export const IconBillingPageCreditCard = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M165.423 131.243C168.453 126.689 170.252 121.082 168.5 115.899C164.635 104.46 152.303 99.875 137.687 99.875L118.822 99.875C104.437 99.875 92.2647 104.316 88.1984 115.362C86.1833 120.836 88.2346 126.845 91.7039 131.534C98.1576 140.257 104.142 144.777 110.454 149.423C115.937 153.459 122.545 155.815 129.353 155.815C134.801 155.815 140.183 154.353 144.725 151.344C153.369 145.618 159.303 140.443 165.423 131.243Z"
      fill="#E1E5EA"
    />
    <path
      d="M104.049 238.59C101.278 237.847 98.2676 236.693 95.0179 235.128C91.7682 233.562 88.1057 232.233 84.0302 231.141C81.2589 230.399 79.9806 231.682 76.6953 233.084C73.5987 234.406 71.7486 235.128 68.4425 236.75C64.6925 238.59 62.4903 239.28 59.0675 240.969C55.3175 242.376 52.0363 243.313 46.4113 244.25C44.0675 244.719 33.2333 245.325 30.005 242.376C26.8201 239.264 26.2553 233.938 27.1928 228.782C28.599 216.594 32.473 211.587 29.5035 199.847C20.6303 185.371 13.1559 169.937 11.9795 158.678C10.8465 147.257 10.5667 136.237 11.1401 125.621C11.7134 115.004 13.2379 105.076 15.7133 95.8375C19.8825 80.2778 26.7255 66.4769 36.2422 54.4347C45.6393 42.1867 56.7378 32.3923 69.5375 25.0516C82.2176 17.5051 96.1047 12.6273 111.199 10.4179C126.173 8.00273 141.322 8.84813 156.646 12.9541C171.643 16.9727 185.061 23.9553 196.897 33.9019C208.615 43.6428 218.115 55.1348 225.4 68.3782C232.684 81.6216 237.349 96.161 239.396 111.997C241.65 127.714 240.67 143.433 236.457 159.155C232.332 174.553 225.902 188.117 217.167 199.847C208.433 211.578 198.22 221.002 186.529 228.118C174.674 235.191 161.754 239.72 147.769 241.706C133.62 243.647 119.047 242.609 104.049 238.59ZM193.876 142.012C195.7 135.205 196.203 127.783 195.386 119.747C194.569 111.711 192.356 103.996 188.746 96.6009C185.017 89.0004 179.842 82.2287 173.223 76.2858C166.647 70.1808 158.55 65.8397 148.932 63.2625C139.15 60.6417 129.544 59.9784 120.111 61.2728C110.679 62.5672 102.018 65.545 94.1301 70.2063C86.122 74.6618 79.1739 80.7041 73.2858 88.3334C67.4411 95.8006 63.1941 104.478 60.5449 114.364C55.7243 132.355 56.7604 147.399 63.6531 159.495C70.3828 171.548 83.4473 180.173 102.846 185.371C113.443 188.21 123.718 189.313 133.672 188.68C143.507 187.84 152.526 185.48 160.73 181.598C168.77 177.672 175.686 172.403 181.477 165.79C187.311 159.015 191.444 151.089 193.876 142.012Z"
      fill="#E1E5EA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M233.083 168.917H168.917H168.917C161.323 168.917 155.167 175.073 155.167 182.667V219.334C155.167 226.928 161.323 233.084 168.917 233.084H233.083C240.677 233.084 246.833 226.928 246.833 219.334V182.667C246.833 175.073 240.677 168.917 233.083 168.917V168.917ZM196.417 214.75H178.083C175.552 214.75 173.5 212.698 173.5 210.167C173.5 207.636 175.552 205.584 178.083 205.584H196.417C198.948 205.584 201 207.636 201 210.167C201 212.698 198.948 214.75 196.417 214.75ZM223.917 214.75H214.75C212.219 214.75 210.167 212.698 210.167 210.167C210.167 207.636 212.219 205.584 214.75 205.584H223.917C226.448 205.584 228.5 207.636 228.5 210.167C228.5 212.698 226.448 214.75 223.917 214.75ZM237.667 187.25H164.333V182.667C164.333 180.136 166.385 178.084 168.917 178.084H233.083C235.615 178.084 237.667 180.136 237.667 182.667V187.25Z"
      fill="#0170F4"
    />
  </svg>
)
