export const IconExport = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="48" cy="48" r="48" fill="#F0F7FF" />
    <path
      d="M61.1111 40H34.6667H34.6667C31.5371 40 29 42.5371 29 45.6667V60.7778C29 63.9074 31.5371 66.4444 34.6667 66.4444H46V70.2222H38.4444C37.4012 70.2222 36.5556 71.0679 36.5556 72.1111C36.5556 73.1543 37.4012 74 38.4444 74H57.3333C58.3765 74 59.2222 73.1543 59.2222 72.1111C59.2222 71.0679 58.3765 70.2222 57.3333 70.2222H49.7778V66.4444H61.1111C64.2407 66.4444 66.7778 63.9074 66.7778 60.7778V45.6667C66.7778 42.5371 64.2407 40 61.1111 40V40ZM62.9999 60.7778C62.9999 61.821 62.1542 62.6667 61.111 62.6667H34.6666C33.6234 62.6667 32.7777 61.821 32.7777 60.7778V45.6667V45.6667C32.7777 44.6235 33.6234 43.7778 34.6666 43.7778H61.111C62.1542 43.7778 62.9999 44.6235 62.9999 45.6667V60.7778Z"
      fill="#0170F4"
    />
    <path
      d="M57.93 33.33L50.43 27.33V27.33C49.8848 26.8992 49.1152 26.8992 48.57 27.33L41.07 33.33V33.33C40.4676 33.8986 40.4402 34.848 41.0088 35.4504C41.5103 35.9817 42.3216 36.0744 42.93 35.67L48 31.62V45C48 45.8284 47.3284 46.5 46.5 46.5H39C38.1716 46.5 37.5 47.1716 37.5 48C37.5 48.8284 38.1716 49.5 39 49.5H46.5C48.9853 49.5 51 47.4853 51 45V31.62L56.07 35.67C56.3339 35.8817 56.6617 35.998 57 36C57.4564 35.9979 57.887 35.7881 58.17 35.43C58.6805 34.7829 58.5734 33.8452 57.93 33.33L57.93 33.33Z"
      fill="#FF4891"
    />
  </svg>
)
