export const IconRegistered = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 36 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5998 0H4.3998C3.97546 0 3.56849 0.168571 3.26843 0.468629C2.96838 0.768687 2.7998 1.17565 2.7998 1.6V14.4C2.7998 14.8243 2.96838 15.2313 3.26843 15.5314C3.56849 15.8314 3.97546 16 4.3998 16H11.5998C12.0242 16 12.4311 15.8314 12.7312 15.5314C13.0312 15.2313 13.1998 14.8243 13.1998 14.4V1.6C13.1998 1.17565 13.0312 0.768687 12.7312 0.468629C12.4311 0.168571 12.0242 0 11.5998 0V0ZM4.3998 14.4V1.6H11.5998V14.4H4.3998Z"
      fill="#BABEC3"
    />
    <path
      d="M8 13.8C7.73478 13.8 7.48043 13.6947 7.29289 13.5072C7.10536 13.3196 7 13.0653 7 12.8C7 12.5348 7.10536 12.2805 7.29289 12.0929C7.48043 11.9054 7.73478 11.8 8 11.8C8.26522 11.8 8.51957 11.9054 8.70711 12.0929C8.89464 12.2805 9 12.5348 9 12.8C9 13.0653 8.89464 13.3196 8.70711 13.5072C8.51957 13.6947 8.26522 13.8 8 13.8Z"
      fill="#BABEC3"
    />
    <path
      d="M30.4948 8.21623C30.6968 7.91257 30.8167 7.53878 30.6999 7.19327C30.4422 6.43065 29.6201 6.125 28.6457 6.125L27.388 6.125C26.4291 6.125 25.6175 6.42107 25.3465 7.15746C25.2121 7.52239 25.3489 7.92298 25.5802 8.23559C26.0104 8.81712 26.4094 9.11848 26.8302 9.42821C27.1957 9.69727 27.6362 9.85432 28.0901 9.85432C28.4533 9.85432 28.8121 9.75687 29.1149 9.55626C29.6912 9.17451 30.0868 8.82956 30.4948 8.21623Z"
      fill="#BABEC3"
    />
    <path
      d="M26.4032 15.3727C26.2184 15.3232 26.0177 15.2463 25.8011 15.1419C25.5844 15.0375 25.3402 14.9489 25.0686 14.8761C24.8838 14.8266 24.7986 14.9122 24.5796 15.0057C24.3731 15.0938 24.2498 15.1419 24.0294 15.25C23.7794 15.3727 23.6326 15.4187 23.4044 15.5313C23.1544 15.6251 22.9356 15.6876 22.5606 15.75C22.4044 15.7813 21.6821 15.8217 21.4669 15.6251C21.2545 15.4176 21.2169 15.0626 21.2794 14.7188C21.3731 13.9063 21.6314 13.5725 21.4334 12.7899C20.8419 11.8248 20.3436 10.7959 20.2652 10.0452C20.1896 9.2838 20.171 8.54919 20.2092 7.8414C20.2474 7.13362 20.3491 6.47177 20.5141 5.85587C20.792 4.81855 21.2482 3.89849 21.8827 3.09568C22.5092 2.27915 23.2491 1.62619 24.1024 1.1368C24.9477 0.633704 25.8735 0.308517 26.8798 0.161224C27.8781 0.000214651 28.888 0.0565746 29.9096 0.330307C30.9094 0.598214 31.8039 1.06372 32.593 1.72683C33.3742 2.37622 34.0076 3.14235 34.4932 4.02524C34.9788 4.90814 35.2898 5.87743 35.4263 6.93314C35.5765 7.98095 35.5112 9.02891 35.2304 10.077C34.9553 11.1035 34.5266 12.0078 33.9444 12.7899C33.3621 13.5719 32.6812 14.2001 31.9018 14.6746C31.1115 15.1461 30.2502 15.4481 29.3178 15.5804C28.3745 15.7098 27.403 15.6406 26.4032 15.3727ZM32.3916 8.9342C32.5132 8.48038 32.5467 7.98559 32.4923 7.44985C32.4378 6.9141 32.2903 6.39974 32.0496 5.90676C31.801 5.40006 31.456 4.94861 31.0147 4.55242C30.5763 4.14542 30.0365 3.85601 29.3953 3.6842C28.7432 3.50948 28.1028 3.46526 27.474 3.55155C26.8451 3.63785 26.2678 3.83637 25.7419 4.14712C25.208 4.44415 24.7448 4.84698 24.3523 5.35559C23.9626 5.85341 23.6795 6.43187 23.5029 7.091C23.1815 8.29039 23.2506 9.29329 23.7101 10.0997C24.1587 10.9032 25.0297 11.4782 26.323 11.8248C27.0294 12.014 27.7144 12.0876 28.378 12.0453C29.0336 11.9894 29.6349 11.832 30.1818 11.5732C30.7179 11.3115 31.1789 10.9602 31.565 10.5194C31.9539 10.0677 32.2294 9.5393 32.3916 8.9342Z"
      fill="#BABEC3"
    />
    <line x1="13" y1="7.5" x2="21" y2="7.5" stroke="#BABEC3" />
  </svg>
)
