export const IconOnboardingComplete = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M165.118 49.9263C165.118 49.9263 137.736 84.6103 93.9245 63.9215C50.113 43.2328 6.30141 99.2142 44.028 133.29C81.7546 167.365 44.8363 186.09 70.4663 206.973C99.5964 230.709 124.958 182.578 148.08 193.531C199.113 217.704 236.92 208.134 228.401 180.144C213.448 131.01 194.326 127.813 208.93 102.257C223.533 76.6999 191.303 23.5612 165.118 49.9263Z"
      fill="#0170F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M156.363 92.9037C153.79 90.8666 150.053 91.3011 148.016 93.8742L147.985 93.914L106.387 147.396L86.0045 122.557C84.0333 119.933 80.3081 119.404 77.6841 121.375C75.0601 123.346 74.531 127.071 76.5022 129.695C76.5765 129.794 76.6539 129.891 76.7343 129.985L101.514 160.767C102.647 162.163 104.351 162.972 106.149 162.965C108.061 163.051 109.897 162.21 111.082 160.707L157.611 101.283C159.586 98.6612 159.062 94.9354 156.44 92.9607C156.415 92.9415 156.389 92.9225 156.363 92.9037ZM186.074 92.9037C183.501 90.8666 179.764 91.3011 177.727 93.8742L177.696 93.914L136.098 147.396L132.473 142.939L124.986 152.566L131.523 160.707C132.655 162.104 134.36 162.912 136.158 162.906C137.966 162.898 139.672 162.067 140.793 160.648L187.322 101.223C189.276 98.5859 188.722 94.8645 186.084 92.911L186.074 92.9037ZM117.023 123.982L109.357 133.609L106.445 129.746L106.426 129.721C104.37 127.163 104.778 123.422 107.337 121.367C107.403 121.315 107.47 121.264 107.538 121.215C110.199 119.294 113.914 119.894 115.835 122.556L117.023 123.982Z"
      fill="white"
    />
  </svg>
)
