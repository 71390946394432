export const IconImportFile = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="48" cy="48" r="48" fill="#F0F7FF" />
    <path
      d="M38.3553 56.4676C37.5429 56.4676 36.8843 57.1262 36.8843 57.9387V60.8808C36.8843 61.6932 37.5429 62.3518 38.3553 62.3518C39.1678 62.3518 39.8264 61.6932 39.8264 60.8808V59.4097H57.4792V60.8808C57.4792 61.6932 58.1379 62.3518 58.9503 62.3518C59.7627 62.3518 60.4214 61.6932 60.4214 60.8808V57.9387C60.4214 57.1262 59.7627 56.4676 58.9503 56.4676L58.9443 56.4676H38.3613L38.3553 56.4676ZM42.7686 71.1782C42.3055 71.1782 41.8695 70.9602 41.5917 70.5898C41.1043 69.9399 41.236 69.0178 41.8859 68.5303L47.7702 64.1171C48.2872 63.7394 48.9891 63.7394 49.5061 64.1171L55.3903 68.2655C56.0505 68.7339 56.2083 69.6477 55.7434 70.3103C55.2771 70.9757 54.3598 71.1371 53.6944 70.6708L53.6839 70.6634L50.1216 68.1531C50.1231 68.1806 50.1239 68.2082 50.1239 68.2361V80.0046C50.1239 80.8171 49.4653 81.4757 48.6528 81.4757C47.8404 81.4757 47.1818 80.8171 47.1818 80.0046V68.2361C47.1818 68.2326 47.1818 68.2292 47.1818 68.2257L43.6512 70.884C43.3966 71.075 43.0869 71.1782 42.7686 71.1782Z"
      fill="#FF4891"
    />
    <path
      d="M61.7225 27.2304L52.4292 16.9804C52.1062 16.6226 51.647 16.4178 51.165 16.4167H39.2067V16.4167C36.8198 16.3882 34.8618 18.3 34.8333 20.6869C34.8333 20.6871 34.8333 20.6873 34.8333 20.6875V46.3125L34.8333 46.3125C34.8614 48.6993 36.8192 50.6115 39.206 50.5833C39.2062 50.5833 39.2064 50.5833 39.2066 50.5833H57.7933C60.1802 50.6118 62.1382 48.7 62.1666 46.3131C62.1666 46.3129 62.1666 46.3127 62.1666 46.3125V28.375V28.375C62.1655 27.9516 62.0073 27.5437 61.7225 27.2304L61.7225 27.2304ZM58.1499 28.3751H51.4191C50.6863 28.303 50.1467 27.6569 50.2061 26.923V19.8334H50.3941L58.1499 28.3751ZM57.7933 47.1667H39.2067C38.7075 47.1957 38.2792 46.8146 38.2502 46.3154C38.2501 46.3144 38.2501 46.3135 38.25 46.3125V20.6875V20.6875C38.2775 20.1882 38.7045 19.8057 39.2038 19.8332C39.2047 19.8332 39.2057 19.8333 39.2067 19.8333H46.7917V26.9229V26.9229C46.7343 29.5425 48.8021 31.7172 51.4213 31.7917H58.75V46.3125C58.7225 46.8118 58.2955 47.1943 57.7962 47.1668C57.7953 47.1668 57.7943 47.1667 57.7933 47.1667V47.1667Z"
      fill="#0170F4"
    />
  </svg>
)
