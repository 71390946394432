export const IconPeopleComplete = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 299 304" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      d="M95.9998 117.333C119.564 117.333 138.666 98.2308 138.666 74.6667C138.666 51.1025 119.564 32 95.9998 32C72.4356 32 53.3331 51.1025 53.3331 74.6667C53.3331 98.2308 72.4356 117.333 95.9998 117.333ZM181.333 138.667C199.006 138.667 213.333 124.34 213.333 106.667C213.333 88.9936 199.006 74.6667 181.333 74.6667C163.66 74.6667 149.333 88.9936 149.333 106.667C149.333 124.34 163.66 138.667 181.333 138.667ZM234.666 202.667C234.666 208.558 229.891 213.333 224 213.333L170.666 213.333C170.666 219.224 165.891 224 160 224H31.9997C26.1086 224 21.333 219.224 21.333 213.333C21.3595 193.616 29.184 174.709 43.0991 160.74C72.2012 131.523 119.477 131.431 148.694 160.533C158.025 153.312 169.489 149.389 181.288 149.379C210.743 149.354 234.642 173.212 234.666 202.667Z"
      fill="#E1E6EA"
    />
    <path
      d="M242.199 175.204C242.199 175.204 225.085 196.882 197.703 183.951C170.321 171.021 142.938 206.009 166.518 227.306C190.097 248.603 167.023 260.306 183.041 273.359C201.248 288.193 217.098 258.111 231.55 264.957C263.446 280.065 287.075 274.084 281.751 256.59C272.405 225.881 260.454 223.883 269.581 207.911C278.708 191.938 258.564 158.726 242.199 175.204Z"
      fill="#0170F4"
    />
    <path
      d="M236.727 202.064C235.119 200.791 232.783 201.063 231.51 202.671L231.491 202.696L205.492 236.122L192.753 220.597C191.521 218.957 189.193 218.627 187.553 219.859C185.913 221.091 185.582 223.419 186.814 225.059C186.861 225.121 186.909 225.181 186.959 225.24L202.447 244.479C203.155 245.351 204.22 245.857 205.344 245.853C206.539 245.906 207.686 245.381 208.426 244.442L237.507 207.301C238.741 205.663 238.414 203.334 236.775 202.1C236.759 202.088 236.743 202.076 236.727 202.064ZM255.298 202.064C253.689 200.791 251.354 201.063 250.08 202.671L250.061 202.696L224.063 236.122L221.797 233.337L217.117 239.353L221.203 244.442C221.911 245.314 222.976 245.82 224.1 245.816C225.23 245.811 226.296 245.291 226.997 244.404L256.078 207.264C257.299 205.616 256.952 203.29 255.304 202.069L255.298 202.064ZM212.141 221.489L207.349 227.506L205.53 225.091L205.517 225.076C204.233 223.477 204.488 221.139 206.087 219.855C206.128 219.822 206.17 219.79 206.213 219.76C207.876 218.559 210.197 218.934 211.398 220.597L212.141 221.489Z"
      fill="white"
    />
  </svg>
)
