export const IconCopy = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 13V12C9 10.3432 10.3432 9 12 9H13V5.67C13 5.29997 12.7 5 12.33 5H5.67C5.29997 5 5 5.29997 5 5.67V12.33C5 12.7 5.29997 13 5.67 13H9ZM9 15H5.67C4.19768 14.9945 3.00548 13.8023 3 12.33V5.67002C3.00547 4.1977 4.19766 3.0055 5.66998 3H12.33C13.8023 3.00547 14.9945 4.19767 15 5.67V9H18C19.6568 9 21 10.3432 21 12V18C21 19.6569 19.6568 21 18 21H12C10.3432 21 9 19.6569 9 18V15ZM12 11C11.4477 11 11 11.4477 11 12V18C11 18.5523 11.4477 19 12 19H18C18.5523 19 19 18.5523 19 18V12C19 11.4477 18.5523 11 18 11L12 11Z"
      fill="#0170F4"
    />
  </svg>
)
