import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { IconClose, IconExpand } from 'src/components/icons'
import { callThunks } from 'src/features/Call/thunks'
import { useCurrentCall } from 'src/hooks/useCurrentValues'
import { useDrawer } from 'src/hooks/useDrawer'
import { useParticipantIds, DailyVideo, DailyAudio } from '@daily-co/daily-react'
import { DrawerCall } from '../../drawers/DrawerCall'

export const CallMiniature = () => {
  const [calleeId, setCalleeId] = useState<string | null>()
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' })
  const dispatch = useDispatch()
  const { callObject } = useCurrentCall()
  const { showDrawer, hideLastDrawer } = useDrawer()

  const returnToCall = () => {
    showDrawer(<DrawerCall onDismiss={hideLastDrawer} />, 'fullscreen')
  }

  const closeCall = () => {
    dispatch(callThunks.closeCall())
  }

  useEffect(() => {
    const callee = remoteParticipantIds?.length > 0 ? remoteParticipantIds[0] : null
    setCalleeId(callee)
  }, [remoteParticipantIds])

  return (
    <>
      {callObject && calleeId && (
        <div className="absolute flex justify-center items-center z-20 bottom-40 left-10 h-1/5 w-1/6 bg-grey-800">
          <div className="h-full w-full group z-30">
            <div
              className="hidden group-hover:block absolute top-2 left-2 bg-primary-500 rounded-md cursor-pointer z-10"
              onClick={returnToCall}
            >
              <IconExpand className="w-6 h-6" />
            </div>
            <div
              className="hidden group-hover:block absolute top-2 right-2 bg-primary-500 rounded-md cursor-pointer z-10"
              onClick={closeCall}
            >
              <IconClose className="w-6 h-6" />
            </div>
          </div>
          <DailyVideo
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: 10 }}
            automirror
            sessionId={calleeId}
            type={'video'}
          />
          <DailyAudio />
        </div>
      )}
    </>
  )
}
