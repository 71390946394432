export const IconCustomerWithDoctor = ({ className }: { className?: string }) => (
  <svg
    className={`fill-current ${className}`}
    viewBox="0 0 254 190"
    preserveAspectRatio="xMidYMin"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="paint0_linear_4855_160488"
        x1="259.79"
        y1="181.672"
        x2="254.881"
        y2="181.672"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0.741121, 0, 0, 0.748541, 12.491213, 18.737217)"
      >
        <stop stopColor="#69D1BD" />
        <stop offset="1" stopColor="#178771" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4855_160488"
        x1="193.844"
        y1="170.828"
        x2="187.102"
        y2="170.828"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0.741121, 0, 0, 0.748541, 12.491213, 18.737217)"
      >
        <stop stopColor="#69D1BD" />
        <stop offset="1" stopColor="#178771" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4855_160488"
        x1="187.437"
        y1="105.707"
        x2="153.739"
        y2="91.6007"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0.741121, 0, 0, 0.748541, 12.491213, 18.737217)"
      >
        <stop stopColor="#DDEDFA" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4855_160488"
        x1="123.277"
        y1="53.1922"
        x2="151.991"
        y2="53.1922"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0.741121, 0, 0, 0.748541, 12.491213, 18.737217)"
      >
        <stop stopColor="#DDEDFA" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4855_160488"
        x1="188.389"
        y1="227.63"
        x2="176.048"
        y2="228.348"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0.741121, 0, 0, 0.748541, 12.491213, 18.737217)"
      >
        <stop stopColor="#005CE6" />
        <stop offset="1" stopColor="#0170F4" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4855_160488"
        x1="213.104"
        y1="227.069"
        x2="188.421"
        y2="227.069"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0.741121, 0, 0, 0.748541, 12.491213, 18.737217)"
      >
        <stop stopColor="#005CE6" />
        <stop offset="1" stopColor="#0170F4" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_4855_160488"
        x1="215.103"
        y1="127.496"
        x2="196.448"
        y2="72.3902"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0.741121, 0, 0, 0.748541, 12.491213, 18.737217)"
      >
        <stop stopColor="#DDEDFA" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_4855_160488"
        x1="214.722"
        y1="94.8054"
        x2="245.433"
        y2="67.8264"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0.741121, 0, 0, 0.748541, 12.491213, 18.737217)"
      >
        <stop stopColor="#DDEDFA" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_4855_160488"
        x1="181.243"
        y1="126.443"
        x2="178.181"
        y2="111.327"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0.741121, 0, 0, 0.748541, 12.491213, 18.737217)"
      >
        <stop stopColor="#DDEDFA" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_4855_160488"
        x1="210.661"
        y1="58.4184"
        x2="213.132"
        y2="71.6634"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0.741121, 0, 0, 0.748541, 12.491213, 18.737217)"
      >
        <stop stopColor="#EBF0FC" />
        <stop offset="1" stopColor="#9FC3FC" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_4855_160488"
        x1="205.484"
        y1="115.061"
        x2="205.269"
        y2="100.352"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0.741121, 0, 0, 0.748541, 12.491213, 18.737217)"
      >
        <stop stopColor="#DDEDFA" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_4855_160488"
        x1="227.827"
        y1="215.077"
        x2="224.67"
        y2="268.174"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0.741121, 0, 0, 0.748541, 12.491213, 18.737217)"
      >
        <stop stopColor="#DDEDFA" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_4855_160488"
        x1="223.954"
        y1="160.7"
        x2="196.114"
        y2="117.935"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0.741121, 0, 0, 0.748541, 12.491213, 18.737217)"
      >
        <stop stopColor="#DDEDFA" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_4855_160488"
        x1="150.986"
        y1="158.546"
        x2="153.425"
        y2="136.59"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0.741121, 0, 0, 0.748541, 12.491213, 18.737217)"
      >
        <stop stopColor="#DDEDFA" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
    <path
      d="M 299.731 57.611 C 302.83 39.783 300.692 20.518 288.191 7.448 C 260.599 -21.401 216.299 -17.998 171.242 2.286 L 113.081 28.468 C 68.736 48.432 37.294 78.608 39.894 117.288 C 41.183 136.456 55.74 151.582 72.861 160.791 C 104.71 177.924 129.354 183.137 155.178 188.273 C 177.611 192.734 201.212 190.609 222.2 181.16 C 238.998 173.599 253.585 161.758 263.464 146.454 C 282.266 117.332 293.471 93.62 299.731 57.611 Z"
      fill="#E6F1FF"
    />
    <path
      d="M 204.949 153.459 C 204.747 151.354 201.535 151.497 201.425 153.459 C 201.313 155.421 201.342 157.916 203.366 157.468 C 205.393 157.019 205.032 154.328 204.949 153.459 Z"
      fill="url(#paint0_linear_4855_160488)"
    />
    <path
      d="M 155.176 143.2 C 156.359 143.808 156.342 144.798 155.787 146.144 C 155.231 147.489 156.48 148.105 155.564 149.423 C 154.649 150.741 150.264 150.208 151.318 146.844 C 152.373 143.481 154.588 142.898 155.176 143.2 Z"
      fill="url(#paint1_linear_4855_160488)"
    />
    <path
      d="M 117.55 133.729 C 112.437 138.612 96.411 136.002 83.913 127.045 C 71.414 118.086 65.439 93.182 65.439 93.182 L 77.434 88.073 C 78.044 89.313 78.657 90.57 79.279 91.835 C 80.139 93.597 81.009 95.366 81.896 97.135 C 89.409 112.146 97.795 126.487 108.07 126.799 C 108.234 126.811 108.388 126.814 108.549 126.809 C 109.377 126.796 110.155 126.797 110.89 126.81 C 121.313 126.954 122.332 129.167 117.55 133.729 Z"
      fill="#FDB2D0"
    />
    <path
      d="M 108.07 126.8 C 106.473 129.468 96.528 128.239 90.904 121.461 C 85.463 114.918 82.88 105.967 81.896 97.135 C 89.41 112.147 97.795 126.488 108.07 126.8 Z"
      fill="#FCCDE0"
    />
    <path
      d="M 117.55 133.729 C 112.437 138.612 96.411 136.002 83.913 127.045 C 71.414 118.086 65.439 93.182 65.439 93.182 L 77.434 88.073 C 78.243 89.14 78.84 90.416 79.279 91.835 C 81.496 99.032 79.619 109.851 81.288 115.224 C 83.277 121.666 87.523 130.884 100.089 133.385 C 111.865 135.73 115.85 130.35 110.89 126.81 C 121.313 126.954 122.332 129.167 117.55 133.729 Z"
      fill="#FE93BC"
    />
    <path
      d="M 85.367 99.685 C 85.367 99.685 83.597 102.143 81.223 105.615 C 78.704 109.301 75.488 114.123 73.004 118.316 C 73.004 118.316 68.554 114.303 68.384 110.1 C 68.223 105.907 71.991 77.978 75.853 80.565 C 78.398 82.266 81.716 90.018 83.681 95.114 C 84.711 97.76 85.367 99.685 85.367 99.685 Z"
      fill="#005CE6"
    />
    <path
      d="M 85.367 99.685 C 85.367 99.685 83.597 102.143 81.223 105.615 C 81.853 104.325 83.172 102.461 83.588 100.476 C 83.886 99.075 83.903 97.099 83.682 95.114 C 84.711 97.76 85.367 99.685 85.367 99.685 Z"
      fill="#4D94FF"
    />
    <path
      d="M 84.742 211.039 C 83.024 216.023 81.084 220.844 81.084 220.844 L 64.024 220.844 C 64.024 220.844 59.651 184.055 58.29 162.081 C 57.864 155.034 57.737 149.517 58.163 147.214 C 58.196 147.059 58.222 146.922 58.256 146.802 C 59.575 141.835 63.999 143.132 67.088 144.773 C 68.891 145.727 70.244 146.802 70.244 146.802 C 72.065 150.454 73.699 154.02 75.154 157.457 L 75.154 157.466 C 75.662 158.654 76.156 159.849 76.634 161.049 C 85.066 182.096 87.057 197.95 87.363 200.795 C 87.542 202.393 86.223 206.776 84.742 211.039 Z"
      fill="#FDA218"
    />
    <path
      d="M 73.648 220.843 L 64.024 220.843 C 64.024 220.843 59.651 184.055 58.29 162.08 C 57.864 155.034 57.737 149.517 58.163 147.213 C 61.064 145.899 64.178 145.057 67.088 144.773 C 68.891 145.727 70.244 146.801 70.244 146.801 C 72.065 150.454 73.699 154.02 75.154 157.457 L 75.154 157.465 C 75.647 167.864 73.852 171.68 76.524 181.158 C 79.196 190.637 73.317 206.251 72.644 212.551 C 72.286 215.885 72.941 218.823 73.648 220.843 Z"
      fill="#FFBB38"
    />
    <path
      d="M 72.644 189.151 C 71.393 194.075 70.832 211.09 68.986 214.33 C 68.245 215.619 68.194 218.205 68.39 220.844 L 64.025 220.844 C 64.025 220.844 59.652 184.055 58.291 162.081 C 63.327 156.658 68.28 164.668 68.28 164.668 C 73.095 174.834 73.887 184.226 72.644 189.151 Z"
      fill="#FFBB38"
    />
    <path
      d="M 84.742 211.039 C 83.024 216.023 81.084 220.844 81.084 220.844 L 78.199 220.844 C 78.685 219.314 79.901 217.691 80.633 215.937 C 81.646 213.53 81.535 209.758 82.386 205.143 C 83.237 200.52 81.22 189.245 81.11 182.07 C 81.008 175.53 77.281 168.38 76.634 161.049 C 85.066 182.096 87.057 197.95 87.363 200.795 C 87.542 202.393 86.223 206.776 84.742 211.039 Z"
      fill="#FDA218"
    />
    <path
      d="M 67.811 209.226 C 65.948 216.204 64.024 220.844 64.024 220.844 L 34.331 220.844 C 34.331 220.844 28.391 188.824 28.391 180.498 C 28.391 172.169 32.322 156.668 38.406 144.756 C 38.406 144.756 50.062 142.711 58.138 141.456 C 62.186 140.829 65.335 140.399 65.676 140.528 C 66.33 140.778 67.097 147.429 68.305 157.664 C 68.849 162.27 69.487 167.607 70.244 173.417 L 70.397 174.628 C 70.518 175.516 70.638 176.404 70.755 177.292 C 70.814 177.747 70.874 178.202 70.925 178.658 C 72.107 189.597 70.006 201.027 67.811 209.226 Z"
      fill="#FCA600"
    />
    <path
      d="M 70.397 174.628 C 65.947 172.711 59.983 154.889 49.833 159.125 C 38.5 163.851 46.863 184.923 51.118 195.622 C 53.968 202.797 53.474 214.15 52.836 220.844 L 34.33 220.844 C 34.33 220.844 30.646 200.967 29.081 188.463 L 29.081 188.438 C 29.056 188.258 29.03 188.068 29.013 187.878 C 28.63 184.768 28.391 182.155 28.391 180.498 C 28.391 172.169 32.322 156.668 38.406 144.756 C 38.406 144.756 50.062 142.711 58.138 141.456 C 59.695 143.072 61.106 144.808 62.374 146.604 C 64.876 150.162 66.833 153.977 68.305 157.664 C 68.849 162.27 69.487 167.607 70.244 173.417 L 70.397 174.628 Z"
      fill="#FFBB38"
    />
    <path
      d="M 62.374 146.604 L 62.365 146.613 C 51.968 149.389 44.047 149.096 39.207 151.537 C 34.467 153.918 28.153 178.847 29.013 187.878 C 28.63 184.768 28.391 182.155 28.391 180.498 C 28.391 172.169 32.322 156.668 38.406 144.756 C 38.406 144.756 50.062 142.711 58.138 141.456 C 59.694 143.072 61.106 144.808 62.374 146.604 Z"
      fill="#EC9B00"
    />
    <path
      d="M 46.521 177.732 C 46.43 177.732 46.34 177.703 46.265 177.65 C 46.19 177.596 46.133 177.52 46.103 177.433 C 46.084 177.377 46.076 177.318 46.079 177.26 C 46.081 177.201 46.097 177.144 46.122 177.09 C 46.146 177.037 46.181 176.989 46.225 176.951 C 46.268 176.911 46.319 176.881 46.373 176.862 C 46.468 176.828 55.884 173.394 60.95 165.175 C 61.011 165.074 61.11 165.002 61.225 164.976 C 61.34 164.949 61.46 164.969 61.56 165.031 C 61.66 165.094 61.731 165.194 61.757 165.31 C 61.783 165.427 61.764 165.548 61.702 165.649 C 56.468 174.14 46.765 177.672 46.669 177.706 C 46.62 177.724 46.57 177.732 46.521 177.732 Z"
      fill="#EC9B00"
    />
    <path
      d="M 75.528 151.344 C 79.672 158.898 82.879 161.786 80.012 162.757 C 78.489 163.272 76.379 162.353 73.971 161.855 C 71.852 161.416 69.496 161.296 67.13 162.757 C 62.077 165.868 55.832 166.942 49.365 161.356 C 47.944 160.127 46.09 159.904 44.005 160.283 C 36.602 161.605 26.418 170.543 23.644 169.201 C 20.088 167.483 30.264 153.621 33.089 142.751 C 35.914 131.881 39.529 114.761 39.266 106.039 C 39.02 97.308 41.733 73.591 44.635 70.153 C 45.621 68.993 49.059 69.07 53.186 69.697 C 59.091 70.583 66.382 72.568 69.888 73.573 C 70.075 73.625 70.257 73.694 70.432 73.779 C 71.512 74.235 72.406 75.051 72.406 75.051 C 77.051 79.838 81.535 86.077 81.425 94.774 C 81.264 102.053 79.493 103.058 75.852 110.07 C 75.422 110.912 74.982 111.748 74.534 112.58 C 74.524 112.588 74.524 112.606 74.516 112.613 C 74.321 112.983 74.116 113.344 73.913 113.714 C 73.086 115.226 72.465 117.091 72.031 119.196 C 69.913 129.413 72.1 145.08 75.528 151.344 Z"
      fill="#0170F4"
    />
    <path
      d="M 46.319 137.01 C 47.246 146.032 51.959 147.029 49.313 150.973 C 46.847 154.66 41.154 156.843 44.004 160.281 C 36.602 161.605 26.417 170.541 23.644 169.201 C 20.088 167.481 30.264 153.621 33.088 142.75 C 35.913 131.879 39.529 114.761 39.265 106.039 C 39.019 97.307 41.732 73.589 44.634 70.152 C 45.621 68.991 49.058 69.069 53.185 69.696 C 56.708 75.093 53.841 80.962 53.841 80.962 C 49.093 92.676 55.542 96.473 55.61 105.566 C 55.686 114.666 45.392 127.985 46.319 137.01 Z"
      fill="#005CE6"
    />
    <path
      d="M 81.425 94.774 C 81.264 102.053 79.493 103.058 75.852 110.07 C 75.422 110.912 74.982 111.748 74.534 112.58 C 74.524 112.588 74.524 112.606 74.516 112.613 C 75.744 108.276 79.73 101.563 79.635 98.253 C 79.422 90.806 74.278 84.401 68.594 75.705 L 68.688 75.266 C 68.688 75.266 69.334 74.536 70.432 73.779 C 71.512 74.235 72.406 75.052 72.406 75.052 C 77.051 79.838 81.535 86.077 81.425 94.774 Z M 80.011 162.756 C 78.489 163.272 76.378 162.352 73.97 161.854 C 74.294 161.605 74.558 161.372 74.719 161.14 C 76.03 159.31 71.639 152.117 68.236 144.142 C 65.318 137.293 67.096 128.021 72.031 119.194 C 69.912 129.413 72.1 145.079 75.527 151.343 C 79.671 158.897 82.879 161.785 80.011 162.756 Z"
      fill="#4D94FF"
    />
    <path
      d="M 51.629 73.856 C 54.561 74.473 57.654 74.948 60.722 75.261 C 63.296 75.525 65.842 75.678 68.241 75.701 L 68.598 75.701 C 70.13 75.706 71.597 75.654 72.971 75.547 C 70.592 72.229 68.666 67.955 67.425 62.734 L 67.427 62.726 C 67.305 62.206 67.185 61.678 67.075 61.142 C 66.813 59.906 66.592 58.607 66.415 57.256 C 66.415 57.256 59.552 54.49 56.763 56.571 C 55.534 57.495 53.454 64.961 51.629 73.856 Z"
      fill="#FDB2D0"
    />
    <path
      d="M 51.628 73.854 C 54.558 74.469 57.652 74.951 60.718 75.264 C 60.93 74.087 60.57 72.225 60.316 68.895 C 59.938 64.006 62.263 65.315 67.073 61.139 C 66.808 59.854 66.587 58.56 66.414 57.259 C 66.414 57.259 59.553 54.493 56.766 56.572 C 55.532 57.494 53.451 64.957 51.628 73.854 Z"
      fill="#FE93BC"
    />
    <path
      d="M 65.279 67.625 C 65.009 70.864 67.347 74.129 68.598 75.701 C 70.13 75.707 71.597 75.655 72.971 75.547 C 70.593 72.229 68.666 67.955 67.425 62.735 C 66.699 63.629 65.491 65.094 65.279 67.625 Z"
      fill="#FCCDE0"
    />
    <path
      d="M 51.015 85.817 C 44.637 93.362 49.294 100.977 45.626 108.2 C 45.464 108.518 45.282 108.825 45.084 109.12 C 45.235 108.412 45.434 107.669 45.669 106.921 C 47.644 100.548 43.338 99.572 44.321 92.621 C 45.303 85.67 54.033 77.597 55.194 73.937 C 56.189 70.79 54.19 65.407 56.062 59.339 C 56.038 60.3 56.097 61.463 56.259 62.875 C 56.937 68.963 57.374 78.279 51.015 85.817 Z"
      fill="#FFBE71"
    />
    <path
      d="M 72.27 179.875 C 71.436 179.893 70.62 179.522 69.776 178.792 C 68.569 177.761 67.302 176.007 65.846 173.645 C 63.54 169.898 60.775 164.613 57.056 158.254 C 51.816 149.299 47.851 136.348 45.426 123.14 C 47.298 121.825 49.416 120.7 51.594 119.746 C 55.015 118.251 58.588 117.185 61.652 116.437 C 63.941 115.879 65.931 115.5 67.361 115.269 C 67.267 116.317 67.19 117.357 67.139 118.387 C 66.97 121.533 68.56 155.856 72.908 161.33 C 77.256 166.804 76.175 179.78 72.27 179.875 Z"
      fill="#FDB2D0"
    />
    <path
      d="M 67.361 115.269 C 65.931 115.5 63.941 115.88 61.651 116.438 C 58.588 117.186 55.015 118.251 51.594 119.746 C 49.416 120.7 47.298 121.826 45.426 123.14 C 44.294 116.937 43.503 110.671 43.069 104.742 C 42.099 91.207 48.931 84.529 56.172 82.957 C 58.452 82.468 60.775 82.476 62.902 82.94 C 63.276 83.018 63.642 83.112 63.999 83.223 C 72.312 85.724 68.552 101.288 67.361 115.269 Z"
      fill="#0170F4"
    />
    <path
      d="M 51.301 118.387 C 51.403 118.838 51.503 119.29 51.599 119.744 C 49.421 120.698 47.302 121.823 45.43 123.138 C 44.299 116.934 43.508 110.669 43.074 104.739 C 42.104 91.205 48.935 84.528 56.176 82.955 C 48.672 95.02 50.042 112.74 51.301 118.387 Z"
      fill="#005CE6"
    />
    <path
      d="M 65.85 173.643 C 63.544 169.896 60.778 164.611 57.061 158.251 C 51.82 149.297 47.855 136.346 45.43 123.138 C 47.302 121.823 49.42 120.698 51.599 119.744 C 53.232 127.28 57.844 149.16 62.081 158.535 C 65.246 165.513 65.391 170.128 65.85 173.643 Z"
      fill="#FE93BC"
    />
    <path
      d="M 46.745 42.365 C 51.447 44.457 55.756 46.084 59.618 47.347 C 59.169 45.387 60.033 43.415 61.486 43.573 C 63.978 43.828 65.865 45.502 65.737 42.284 C 65.603 39.056 65.428 39.363 70.972 36.349 C 71.799 35.904 72.465 35.304 72.968 34.617 C 72.968 34.617 72.976 34.619 72.977 34.611 C 75.833 30.67 73.356 23.58 64.613 21.599 C 61.395 20.758 58.364 21.111 55.803 22.368 C 50.836 24.807 47.595 30.624 48.043 37.66 C 48.141 39.205 47.646 40.77 46.745 42.365 Z"
      fill="#FDA218"
    />
    <path
      d="M 55.803 22.368 C 56.017 22.473 56.389 22.522 56.934 22.485 C 60.099 22.246 66.848 22.267 70.784 25.026 C 74.096 27.343 74.197 31.213 72.978 34.611 C 75.834 30.67 73.357 23.58 64.613 21.599 C 61.395 20.758 58.365 21.111 55.803 22.368 Z"
      fill="#FFBE71"
    />
    <path
      d="M 54.898 58.738 C 56.281 59.256 57.782 59.761 59.326 60.192 C 62.868 61.165 66.606 61.719 69.473 61.112 C 71.089 60.775 72.425 60.064 73.298 58.859 C 74.591 57.073 74.788 54.503 74.577 52.031 C 74.293 48.573 73.239 45.292 73.347 44.55 C 73.495 43.486 73.977 43.932 74.879 43.504 C 75.058 43.415 75.254 43.288 75.475 43.106 C 76.763 42.044 74.459 39.909 73.171 39.198 C 72.61 38.886 72.401 37.81 72.283 36.465 L 72.286 36.457 C 72.429 31.087 69.625 29.522 69.237 27.988 C 69.237 27.988 69.95 29.612 66.97 29.574 C 64.669 29.524 61.673 30.232 58.266 32.884 C 50.461 38.961 48.577 56.337 54.898 58.738 Z"
      fill="#FDB2D0"
    />
    <path
      d="M 69.474 61.112 C 71.09 60.775 72.426 60.064 73.299 58.859 C 74.592 57.073 74.789 54.503 74.578 52.031 C 73.879 55.475 72.266 59.208 69.474 61.112 Z M 72.283 36.465 C 72.172 37.216 72.07 38.508 72.631 39.113 C 73.442 39.991 75.377 41.137 75.02 41.937 C 74.782 42.488 74.732 43.059 74.879 43.504 C 75.058 43.415 75.255 43.288 75.475 43.106 C 76.763 42.044 74.46 39.909 73.171 39.198 C 72.611 38.886 72.401 37.81 72.283 36.465 Z"
      fill="#FCCDE0"
    />
    <path
      d="M 56.973 62.875 C 58.356 63.392 59.857 63.897 61.401 64.328 C 61.742 63.607 61.536 62.626 60.998 60.948 C 59.975 57.766 63.576 54.721 63.576 54.721 C 63.576 54.721 55.678 50.923 55.923 46.716 C 56.17 42.511 61.246 44.853 62.946 45.751 C 64.649 46.64 63.516 45.447 65.829 43.228 C 70.455 38.785 70.05 37.861 71.414 36.438 C 71.414 36.438 71.649 36.471 69.046 33.71 C 66.744 33.661 63.749 34.368 60.341 37.021 C 52.537 43.098 50.652 60.473 56.973 62.875 Z"
      fill="#FE93BC"
    />
    <path
      d="M 70.431 38.43 C 70.429 38.439 70.422 38.437 70.422 38.437 C 70.084 38.557 69.749 38.682 69.414 38.81 C 64.548 40.6 64.724 40.537 64.423 43.56 C 64.097 46.761 62.465 44.831 60.033 44.226 C 57.594 43.61 55.986 49.476 60.427 51.541 C 64.869 53.599 64.78 53.38 63.546 54.399 C 62.136 55.56 61.084 56.579 60.856 59.764 C 60.785 60.722 60.788 61.887 60.88 63.305 C 61.262 69.419 61.245 78.746 54.527 85.96 C 47.79 93.178 52.071 101.016 48.057 108.048 C 47.878 108.358 47.682 108.656 47.47 108.94 C 43.014 115.02 32.151 114.502 25.919 116.726 C 19.417 119.054 20.839 123.532 18.212 124.446 C 15.574 125.368 9.647 114.224 14.098 104.642 C 18.549 95.051 28.906 85.94 23.292 70.403 C 17.675 54.874 46.718 47.107 47.552 36.451 C 47.93 31.667 49.904 27.684 52.791 25.097 C 56.329 21.91 61.233 20.828 66.198 22.917 C 69.436 24.154 71.636 26.018 72.93 28.039 C 72.93 28.039 72.939 28.04 72.938 28.049 C 75.579 32.179 74.397 36.955 70.431 38.43 Z"
      fill="#FDA218"
    />
    <path d="M 70.433 38.429 C 74.401 36.953 75.576 32.176 72.939 28.047 C 73.486 31.289 72.657 35.416 70.433 38.429 Z" fill="#FFBE71" />
    <path
      d="M 62.987 30.951 C 62.75 31.55 62.597 32.18 62.297 32.743 C 61.986 33.301 61.715 33.879 61.289 34.356 C 60.546 35.387 59.535 36.18 58.534 36.945 C 56.476 38.428 54.238 39.696 52.206 41.298 C 50.151 42.872 48.366 44.815 46.91 46.971 C 45.401 49.109 44.398 51.536 43.461 53.956 C 42.657 56.419 41.944 58.924 41.568 61.505 C 41.199 64.082 41.128 66.712 41.391 69.29 C 41.634 71.869 42.072 74.402 41.981 76.953 C 41.948 79.508 41.225 82.008 40.258 84.386 C 41.4 82.09 42.221 79.58 42.398 76.975 C 42.576 74.371 42.203 71.785 42.043 69.238 C 41.887 66.687 41.935 64.138 42.333 61.623 C 42.725 59.107 43.452 56.656 44.264 54.24 C 45.156 51.869 46.109 49.491 47.552 47.407 C 48.936 45.293 50.666 43.431 52.611 41.813 C 54.551 40.181 56.742 38.856 58.784 37.279 C 59.767 36.448 60.758 35.586 61.469 34.49 C 61.879 33.982 62.129 33.377 62.414 32.796 C 62.679 32.208 62.792 31.56 62.987 30.951 Z M 39.83 53.674 C 39.698 54.008 39.613 54.36 39.445 54.674 C 39.272 54.985 39.121 55.308 38.883 55.574 C 38.468 56.149 37.904 56.592 37.345 57.019 C 36.197 57.847 34.948 58.554 33.813 59.449 C 32.666 60.327 31.67 61.412 30.856 62.615 C 30.015 63.809 29.455 65.163 28.933 66.514 C 28.484 67.888 28.086 69.287 27.876 70.727 C 27.67 72.165 27.63 73.633 27.776 75.073 C 27.912 76.513 28.157 77.926 28.107 79.349 C 28.088 80.776 27.684 82.172 27.144 83.499 C 27.781 82.217 28.24 80.816 28.339 79.363 C 28.438 77.909 28.23 76.465 28.14 75.043 C 28.054 73.62 28.08 72.197 28.303 70.793 C 28.521 69.389 28.927 68.021 29.38 66.672 C 29.877 65.349 30.41 64.022 31.214 62.859 C 31.989 61.678 32.953 60.64 34.039 59.736 C 35.121 58.825 36.344 58.086 37.484 57.205 C 38.033 56.742 38.586 56.26 38.983 55.649 C 39.212 55.365 39.352 55.028 39.51 54.704 C 39.658 54.376 39.721 54.014 39.83 53.674 Z"
      fill="#FC8000"
    />
    <path
      d="M 57.885 49.888 C 56.72 50.836 55.5 51.806 54.735 53.163 C 53.926 54.477 53.542 56.029 53.45 57.566 C 53.351 59.109 53.481 60.635 53.521 62.152 C 53.589 63.67 53.557 65.18 53.473 66.685 C 53.224 69.686 52.605 72.653 51.566 75.483 C 50.536 78.322 49.353 81.116 47.902 83.749 C 46.467 86.387 44.754 88.879 42.628 90.988 C 40.539 93.136 38.087 94.891 35.611 96.611 C 33.152 98.337 30.5 99.886 28.309 102.047 C 27.231 103.13 26.249 104.33 25.532 105.689 C 24.82 107.03 24.343 108.592 24.603 110.107 C 24.496 109.352 24.624 108.586 24.83 107.865 C 25.04 107.14 25.341 106.446 25.729 105.8 C 26.487 104.5 27.496 103.369 28.595 102.353 C 30.792 100.301 33.444 98.836 35.973 97.162 C 38.518 95.521 41.002 93.717 43.175 91.543 C 45.374 89.395 47.162 86.847 48.644 84.165 C 50.104 81.462 51.274 78.627 52.293 75.743 C 53.317 72.85 53.972 69.802 54.123 66.725 C 54.171 65.188 54.162 63.65 54.054 62.126 C 53.974 60.603 53.814 59.087 53.864 57.585 C 53.893 56.086 54.196 54.59 54.929 53.277 L 55.524 52.321 L 56.255 51.461 C 56.484 51.159 56.771 50.912 57.05 50.657 L 57.885 49.888 Z"
      fill="#FC8000"
    />
    <path
      d="M 72.27 179.875 C 71.436 179.893 70.62 179.523 69.777 178.793 C 70.108 178.286 70.441 177.779 70.755 177.289 C 73.691 172.786 72.687 168.076 69.47 162.912 C 66.263 157.739 62.264 128.331 61.771 119.746 C 61.711 118.775 61.677 117.658 61.652 116.438 C 63.941 115.88 65.931 115.501 67.361 115.27 C 67.267 116.318 67.191 117.357 67.14 118.388 C 66.97 121.534 68.56 155.857 72.908 161.331 C 77.256 166.805 76.175 179.781 72.27 179.875 Z"
      fill="#FCCDE0"
    />
    <path
      d="M 67.361 115.27 C 65.931 115.501 63.941 115.88 61.652 116.438 C 61.507 107.268 62.273 91.921 62.902 82.941 C 63.277 83.018 63.642 83.112 63.999 83.224 C 72.312 85.724 68.552 101.288 67.361 115.27 Z"
      fill="#4D94FF"
    />
    <path
      d="M 119.96 55.716 L 119.679 59.154 L 110.804 60.098 L 109.588 60.227 C 109.588 60.227 109.418 59.875 109.162 59.299 C 109.145 59.274 109.137 59.248 109.129 59.223 C 108.737 58.346 108.193 57.005 107.792 55.605 L 107.792 55.596 C 107.205 53.558 106.916 51.403 107.843 50.346 C 109.861 48.033 114.855 47.655 113.153 45.404 C 111.45 43.152 108.584 36.974 110.438 37.566 C 111.34 37.85 113.323 40.05 115.263 43.247 C 115.909 44.329 116.565 45.516 117.168 46.779 C 118.462 49.52 119.543 52.623 119.96 55.716 Z"
      fill="#FDB2D0"
    />
    <path
      d="M 114.642 43.951 C 114.769 45.833 115.229 47.225 112.89 48.876 C 110.626 50.465 107.257 51.668 107.792 55.596 C 107.205 53.558 106.916 51.403 107.843 50.346 C 109.861 48.033 114.855 47.655 113.153 45.404 C 111.45 43.152 108.584 36.974 110.438 37.566 C 111.34 37.85 113.323 40.05 115.263 43.247 C 114.855 43.265 114.608 43.479 114.642 43.951 Z"
      fill="#FCCDE0"
    />
    <path
      d="M 119.96 55.716 L 119.679 59.154 L 110.804 60.098 C 109.877 59.78 109.323 59.411 109.162 59.299 C 109.128 59.274 109.111 59.257 109.111 59.257 C 109.111 59.257 109.12 59.248 109.128 59.223 C 109.316 58.835 111.263 54.908 114.582 52.872 C 117.228 51.248 117.245 49.194 117.168 46.779 C 118.462 49.52 119.542 52.623 119.96 55.716 Z"
      fill="#FE93BC"
    />
    <path
      d="M 151.822 103.596 C 147.777 111.853 128.409 117.434 119.805 111.116 C 111.201 104.791 107.572 77.556 107.856 63.139 C 107.861 62.867 108.275 62.361 108.281 62.089 C 108.422 62.073 112.665 58.485 111.897 60.872 C 110.896 63.981 118.956 59.35 122.177 58.554 C 123.297 69.214 124.45 88.067 130.118 88.244 C 136.905 88.467 152.127 76.154 155.056 74.912 C 157.977 73.667 155.865 95.347 151.822 103.596 Z"
      fill="url(#paint2_linear_4855_160488)"
    />
    <path
      d="M 125.135 59.84 C 125.135 59.84 123.598 60.258 121.359 60.81 C 120.974 60.91 120.573 61.005 120.153 61.114 C 116.932 61.908 112.77 62.845 109.569 63.33 C 109.425 63.354 109.283 63.371 109.141 63.387 C 108.227 63.517 107.402 63.597 106.72 63.619 C 104.976 63.671 102.675 57.731 104.558 58.067 C 104.899 58.127 105.704 58.019 106.812 57.807 C 107.489 57.677 108.271 57.508 109.131 57.31 C 112.376 56.568 116.631 55.424 119.681 54.575 C 120.102 54.459 120.498 54.345 120.867 54.244 C 122.498 53.801 123.559 53.489 123.559 53.489 L 125.135 59.84 Z"
      fill="url(#paint3_linear_4855_160488)"
    />
    <path
      d="M 146.872 157.626 C 146.872 157.626 152.389 157.05 155.814 158.011 C 159.239 158.971 154.103 220.844 154.103 220.844 L 142.117 220.844 C 142.117 220.844 139.263 196.924 140.214 188.708 C 141.166 180.492 146.872 157.626 146.872 157.626 Z"
      fill="url(#paint4_linear_4855_160488)"
    />
    <path
      d="M 154.103 220.844 L 188.061 220.844 C 188.061 220.844 190.249 194.467 195.956 181.619 C 201.663 168.771 183.59 155.32 169.702 156.665 L 155.814 158.011 C 155.814 158.011 149.916 177.225 151.438 192.214 C 152.96 207.201 154.103 220.844 154.103 220.844 Z"
      fill="url(#paint5_linear_4855_160488)"
    />
    <path
      d="M 207.239 207.916 L 154.436 207.916 C 152.905 206.489 152.445 201.402 152.521 201.178 C 152.368 201.376 151.441 206.919 148.268 207.916 L 129.864 207.916 C 129.864 207.916 129.889 200.783 130.169 189.706 C 130.476 177.52 131.097 160.565 132.365 143.095 C 132.637 139.468 132.927 135.825 133.241 132.19 C 136.083 100.127 141.29 69.336 150.854 66.071 C 171.461 59.033 173.222 59.952 173.222 59.952 C 178.063 59.273 179.808 59.685 182.948 60.656 C 186.086 61.628 192.587 63.673 199.028 72.696 C 203.07 78.36 205.273 130.187 206.353 167.99 C 206.991 190.42 207.239 207.916 207.239 207.916 Z"
      fill="white"
    />
    <path
      d="M 174.966 56.653 L 174.966 60.459 C 173.578 68.339 167.36 68.073 167.36 68.073 C 167.071 67.996 164.1 68.735 162.211 68.348 C 161.429 68.184 160.833 67.824 160.688 67.127 C 160.621 66.809 160.561 66.501 160.501 66.191 C 160.033 63.767 159.778 61.765 159.676 60.115 C 159.659 59.909 159.651 59.711 159.634 59.514 C 159.6 58.861 159.591 58.268 159.608 57.735 C 159.685 53.799 160.749 52.846 160.749 52.846 L 169.384 55.157 L 174.966 56.653 Z"
      fill="#FDB2D0"
    />
    <path
      d="M 174.966 31.947 L 174.966 56.662 C 174.966 56.662 172.167 56.971 168.763 57.263 C 165.658 57.521 162.05 57.77 159.609 57.736 L 159.482 57.736 C 159.259 57.744 159.056 57.736 158.861 57.726 C 154.078 57.538 151.823 53.25 151.823 50.018 C 151.823 49.554 151.815 48.79 151.806 47.819 C 151.781 44.939 151.713 40.222 151.654 36.174 C 151.638 34.464 151.616 32.754 151.586 31.044 C 151.586 30.837 151.586 30.64 151.576 30.46 C 151.56 29.265 151.551 28.534 151.551 28.534 C 151.551 28.534 154.077 28.371 159.736 25.355 C 160.034 25.191 160.34 25.072 160.655 24.977 C 166.373 23.31 174.966 31.947 174.966 31.947 Z"
      fill="#FDB2D0"
    />
    <path
      d="M 174.966 31.947 L 174.966 60.459 C 173.579 68.339 167.36 68.074 167.36 68.074 C 167.071 67.996 164.1 68.736 162.212 68.348 C 161.583 67.722 161.004 66.982 160.501 66.191 C 160.034 63.768 159.778 61.765 159.676 60.116 C 160.944 59.978 162.144 61.714 165.87 61.345 C 166.994 61.233 167.955 59.617 168.763 57.263 C 168.985 56.618 169.189 55.905 169.385 55.157 C 171.205 48.257 171.954 37.919 171.486 36.725 C 170.78 34.929 168.517 31.327 165.87 31.001 C 163.335 30.692 154.733 33.536 151.586 31.044 C 151.586 30.837 151.586 30.64 151.576 30.46 C 152.759 29.806 156.554 27.675 160.655 24.977 C 166.372 23.31 174.966 31.947 174.966 31.947 Z"
      fill="#FE93BC"
    />
    <path
      d="M 151.343 30.652 C 151.343 30.652 155.259 32.63 160.42 30.11 C 163.807 28.457 167.741 27.678 168.597 28.373 C 169.453 29.067 169.592 32.572 172.912 33.637 C 172.912 33.637 172.6 40.317 175.24 40.113 C 177.882 39.909 176.364 29.537 176.364 29.537 L 169.949 26.271 L 162.924 24.837 L 156.277 25.696 L 151.124 27.05 L 151.343 30.652 Z"
      fill="#002966"
    />
    <path
      d="M 174.744 37.969 C 174.852 37.914 179.105 36.367 179.235 40.84 C 179.365 45.313 174.891 44.522 174.88 44.394 C 174.868 44.266 174.744 37.969 174.744 37.969 Z"
      fill="#FDB2D0"
    />
    <path
      d="M 176.184 42.66 C 176.203 42.644 176.262 42.712 176.395 42.769 C 176.524 42.824 176.743 42.858 176.972 42.763 C 177.435 42.576 177.807 41.815 177.805 41.008 C 177.805 40.6 177.709 40.215 177.557 39.898 C 177.413 39.575 177.197 39.352 176.959 39.317 C 176.723 39.269 176.56 39.416 176.51 39.544 C 176.454 39.671 176.486 39.76 176.462 39.767 C 176.45 39.78 176.364 39.696 176.395 39.51 C 176.41 39.421 176.46 39.314 176.561 39.225 C 176.665 39.134 176.819 39.085 176.985 39.098 C 177.326 39.112 177.643 39.421 177.811 39.767 C 177.997 40.117 178.113 40.549 178.113 41.007 C 178.112 41.909 177.679 42.766 177.044 42.972 C 176.736 43.063 176.473 42.975 176.336 42.874 C 176.195 42.767 176.169 42.668 176.184 42.66 Z"
      fill="#FE93BC"
    />
    <path
      d="M 170.276 138.491 C 171.11 139.521 171.958 140.562 173.026 141.34 C 174.096 142.117 175.429 142.611 176.73 142.401 C 178.03 142.191 179.237 141.15 179.379 139.827 C 179.521 138.504 178.324 137.123 177.021 137.318 C 177.891 137.395 178.687 136.448 178.471 135.593 C 178.256 134.738 177.109 134.292 176.381 134.779 C 177.511 134.644 177.756 132.865 176.915 132.092 C 176.075 131.319 174.763 131.386 173.683 131.744 C 172.604 132.102 171.593 132.703 170.467 132.868 C 168.704 133.126 166.969 132.273 165.491 131.265 C 164.014 130.257 162.627 129.053 160.944 128.457 C 158.88 127.725 156.577 127.998 154.506 128.713 C 152.788 129.307 151.137 130.224 149.952 131.614 C 148.767 133.004 148.099 134.913 148.465 136.711 C 148.83 138.508 150.356 140.084 152.167 140.215 C 152.167 140.215 156.213 140.908 159.694 138.65 C 163.177 136.394 170.144 137.201 170.144 137.201 L 170.276 138.491 Z"
      fill="#002966"
    />
    <path
      d="M 176.755 24.451 C 176.762 24.466 176.67 24.531 176.487 24.64 C 176.307 24.754 176.027 24.897 175.663 25.074 C 174.932 25.42 173.828 25.842 172.405 26.13 C 170.985 26.415 169.235 26.54 167.325 26.269 C 165.406 26.009 163.427 25.242 161.356 24.578 C 159.292 23.911 157.223 23.509 155.392 23.833 C 153.565 24.129 152.072 25.133 151.364 26.335 C 150.645 27.549 150.639 28.782 150.815 29.537 C 150.992 30.313 151.29 30.671 151.246 30.696 C 151.233 30.706 151.16 30.621 151.031 30.447 C 150.919 30.264 150.75 29.985 150.637 29.584 C 150.397 28.804 150.343 27.494 151.075 26.166 C 151.804 24.839 153.392 23.737 155.317 23.401 C 157.254 23.034 159.409 23.449 161.496 24.123 C 163.593 24.797 165.534 25.561 167.389 25.834 C 169.24 26.121 170.947 26.036 172.346 25.799 C 175.158 25.31 176.717 24.354 176.755 24.451 Z M 178.267 31.453 C 178.315 31.483 178.143 31.907 177.631 32.426 C 177.13 32.94 176.243 33.516 175.128 33.682 C 174.014 33.845 173.001 33.55 172.375 33.201 C 171.738 32.853 171.452 32.496 171.49 32.454 C 171.535 32.395 171.888 32.648 172.515 32.896 C 173.135 33.145 174.06 33.357 175.06 33.21 C 176.06 33.062 176.886 32.591 177.41 32.173 C 177.94 31.756 178.207 31.411 178.267 31.453 Z M 177.043 29.164 C 177.152 29.225 176.761 30.009 175.868 30.541 C 174.98 31.08 174.11 31.055 174.11 30.93 C 174.092 30.793 174.841 30.614 175.627 30.131 C 176.42 29.664 176.932 29.084 177.043 29.164 Z"
      fill="#002966"
    />
    <path
      d="M 148.983 33.628 C 148.923 33.638 148.813 33.411 148.807 33.01 C 148.8 32.616 148.925 32.044 149.298 31.543 C 149.671 31.044 150.178 30.763 150.556 30.662 C 150.939 30.556 151.185 30.598 151.192 30.659 C 151.221 30.79 150.299 30.983 149.675 31.83 C 149.043 32.672 149.115 33.619 148.983 33.628 Z"
      fill="#002966"
    />
    <path
      d="M 147.335 26.867 C 147.469 26.822 147.875 28.032 148.96 29.08 C 150.025 30.15 151.234 30.529 151.192 30.665 C 151.189 30.789 149.805 30.591 148.631 29.421 C 147.444 28.266 147.212 26.873 147.335 26.867 Z"
      fill="#002966"
    />
    <path
      d="M 165.468 65.729 L 164.856 122.361 C 162.856 114.998 152.748 85.925 152.748 85.925 L 158.227 83.78 C 157.427 81.492 150.604 73.676 150.604 73.676 L 158.227 63.619 C 158.227 63.619 162.329 67.456 162.856 67.159 C 162.984 67.086 163.996 66.527 165.468 65.729 Z"
      fill="url(#paint6_linear_4855_160488)"
    />
    <path
      d="M 168.551 91.518 L 174.966 90.172 C 173.375 93.138 164.663 123.927 164.663 123.927 L 165.274 67.295 C 169.877 64.768 178.931 59.823 178.931 59.823 L 179.893 73.014 C 176.379 75.063 168.551 91.518 168.551 91.518 Z"
      fill="url(#paint7_linear_4855_160488)"
    />
    <path
      d="M 139.796 85.149 C 143.093 82.034 149.104 89.828 149.203 95.65 C 149.3 101.472 152.01 107.943 148.305 109.466 C 144.599 110.99 139.052 110.35 139.052 111.123 C 139.052 111.895 139.721 112.648 144.65 112.648 C 149.581 112.648 151.496 112.111 151.496 108.78 C 151.496 105.45 151.07 95.147 149.262 90.093 C 147.454 85.041 143.132 83.684 139.796 85.149 Z"
      fill="url(#paint8_linear_4855_160488)"
    />
    <path
      d="M 178.935 59.826 C 178.935 59.826 177.675 60.125 176.405 61.982 C 175.707 63.013 172.335 69.505 169.452 75.091 C 168.019 77.888 166.59 80.687 165.163 83.488 L 161.991 72.322 L 160.957 68.686 L 159.191 66.384 L 158.036 63.69 L 158.036 59.617 C 158.036 59.617 162.992 62.959 164.402 62.817 C 168.485 62.404 175.532 55.178 175.532 55.178 L 178.935 59.826 Z"
      fill="url(#paint9_linear_4855_160488)"
    />
    <path
      d="M 169.452 75.092 C 168.019 77.888 166.59 80.687 165.163 83.488 L 161.99 72.323 C 162.435 70.804 162.888 69.363 163.106 68.592 C 163.088 68.576 163.051 68.569 163.024 68.553 C 162.199 67.805 161.301 64.343 161.365 63.541 C 161.428 62.746 163.395 62.793 164.401 62.817 C 164.801 62.832 168.4 61.911 169.198 62.674 C 169.86 63.304 168.372 66.884 167.357 68.073 C 167.792 69.395 168.826 72.708 169.452 75.092 Z"
      fill="#005CE6"
    />
    <path
      d="M 135.468 109.946 C 134.122 109.814 132.512 109.351 132.084 108.676 C 131.783 108.312 131.655 107.08 131.857 105.487 C 132.21 102.299 133.509 97.652 134.382 93.955 L 133.307 93.696 C 132.443 97.365 131.133 102.017 130.757 105.362 C 130.587 107.049 130.563 108.4 131.187 109.33 C 132.11 110.549 133.906 110.866 135.344 111.054 C 136.517 111.188 137.466 111.165 137.483 111.163 L 137.457 110.051 C 137.454 110.05 136.562 110.071 135.468 109.946 Z"
      fill="#86A8FF"
    />
    <path
      d="M 136.866 109.468 C 136.105 109.441 135.471 109.832 135.453 110.345 C 135.435 110.858 136.036 111.297 136.798 111.327 C 137.561 111.353 138.194 110.96 138.213 110.448 C 138.232 109.936 137.63 109.496 136.866 109.468 Z"
      fill="#07004B"
    />
    <path
      d="M 150.309 95.64 L 149.203 95.65 C 149.236 99.451 149.472 104.274 149.112 107.463 C 148.952 109.059 148.554 110.232 148.183 110.52 C 147.616 111.077 145.943 111.168 144.6 110.989 C 143.51 110.865 142.645 110.641 142.642 110.642 L 142.371 111.724 C 142.385 111.726 143.304 111.963 144.477 112.098 C 145.922 112.24 147.742 112.338 148.913 111.358 C 149.723 110.591 150 109.272 150.209 107.589 C 150.582 104.242 150.339 99.414 150.309 95.64 Z"
      fill="#86A8FF"
    />
    <path
      d="M 143.348 110.209 C 142.598 110.064 141.913 110.357 141.817 110.862 C 141.723 111.366 142.252 111.891 143 112.038 C 143.752 112.181 144.435 111.889 144.53 111.386 C 144.624 110.881 144.096 110.354 143.348 110.209 Z M 143.023 83.943 C 138.058 83.421 133.768 88.173 133.07 94.301 L 134.505 94.464 C 134.815 91.676 135.948 89.264 137.483 87.636 C 139.027 86.005 140.927 85.169 142.858 85.39 C 144.788 85.612 146.456 86.856 147.597 88.795 C 148.734 90.728 149.304 93.336 148.99 96.121 L 150.422 96.285 C 151.101 90.156 147.973 84.555 143.023 83.943 Z"
      fill="#07004B"
    />
    <path
      d="M 156.577 57.307 C 157.215 57.523 157.984 57.668 158.722 57.722 C 149.096 63.41 144.287 74.962 143.891 84.577 L 142.369 84.516 C 142.833 73.148 148.381 62.917 156.577 57.307 Z M 177.047 69.928 C 171.568 79.92 168.022 88.274 167.36 91.372 L 165.868 91.042 C 166.538 87.937 169.902 79.773 175.716 69.182 C 178.387 64.309 178.683 59.767 176.499 57.023 C 176.074 56.485 175.556 56.024 174.962 55.647 L 174.962 53.903 C 176.043 54.425 176.956 55.147 177.686 56.062 C 180.266 59.306 180.03 64.494 177.047 69.928 Z"
      fill="#07004B"
    />
    <path
      d="M 164.664 103.78 C 168.406 103.78 171.44 100.716 171.44 96.937 C 171.44 93.157 168.406 90.093 164.664 90.093 C 160.923 90.093 157.889 93.157 157.889 96.937 C 157.889 100.716 160.923 103.78 164.664 103.78 Z"
      fill="url(#paint10_linear_4855_160488)"
    />
    <path
      d="M 165.107 100.749 C 168.339 100.749 170.958 98.103 170.958 94.84 C 170.958 91.578 168.339 88.932 165.107 88.932 C 161.877 88.932 159.258 91.578 159.258 94.84 C 159.258 98.103 161.877 100.749 165.107 100.749 Z"
      fill="#07004B"
    />
    <path
      d="M 206.353 167.99 C 204.78 181.345 202.379 196.1 199.325 198.085 C 194.57 201.17 165.513 201.266 155.732 201.209 C 155.568 193.797 163.798 112.073 163.798 112.073 L 153.652 190.943 L 152.521 201.179 C 152.444 201.402 152.904 206.489 154.436 207.916 L 207.239 207.916 C 207.239 207.916 206.991 190.42 206.353 167.99 Z"
      fill="url(#paint11_linear_4855_160488)"
    />
    <path
      d="M 126.137 124.988 C 125.728 127.868 125.193 130.781 124.724 133.127 C 124.46 134.441 124.222 135.584 124.035 136.435 C 117.552 135.378 112.608 134.329 111.034 133.703 C 108.601 132.748 107.954 130.308 109.043 128.099 C 109.486 127.214 110.2 126.364 111.196 125.659 C 112.183 124.954 113.459 124.404 114.999 124.121 C 116.471 123.836 118.199 123.802 120.164 124.103 C 120.819 124.198 121.499 124.301 122.214 124.413 C 122.368 124.439 122.529 124.455 122.69 124.481 C 123.839 124.654 124.988 124.823 126.137 124.988 Z"
      fill="#FDB2D0"
    />
    <path
      d="M 122.214 124.413 C 121.414 125.298 119.543 126.26 117.782 125.899 C 115.612 125.443 115.229 126.965 111.196 125.659 C 112.183 124.954 113.459 124.404 114.999 124.121 C 116.471 123.836 118.199 123.802 120.164 124.103 C 120.819 124.198 121.499 124.301 122.214 124.413 Z"
      fill="#FCCDE0"
    />
    <path
      d="M 126.137 124.988 C 125.728 127.868 125.193 130.781 124.724 133.127 C 124.46 134.441 124.222 135.584 124.035 136.435 C 117.552 135.378 112.608 134.329 111.034 133.703 C 108.601 132.748 107.954 130.308 109.043 128.099 C 109.307 131.537 115.798 132.285 117.636 131.554 C 118.93 131.038 120.819 127.334 122.69 124.482 C 123.839 124.655 124.988 124.823 126.137 124.988 Z"
      fill="#FE93BC"
    />
    <path
      d="M 198.464 98.045 C 193.518 112.479 184.693 139.354 178.935 141.16 C 167.283 144.816 139.472 140.863 124.04 138.603 C 124.557 136.466 126.265 126.986 126.904 122.942 C 138.403 124.365 154.734 125.486 161.361 119.184 C 169.23 111.69 170.747 85.533 178.935 76.072 C 185.83 68.109 203.41 83.604 198.464 98.045 Z"
      fill="url(#paint12_linear_4855_160488)"
    />
    <path
      d="M 127.045 141.16 L 120.505 140.359 C 120.505 140.359 120.669 138.734 120.96 136.386 C 121.382 132.891 122.084 127.829 122.89 124.165 C 123.14 123.066 123.392 122.093 123.656 121.328 C 124.239 119.641 130.66 119.595 129.657 121.28 C 129.478 121.584 129.286 122.394 129.086 123.535 C 128.42 127.232 127.691 134.34 127.313 138.31 C 127.141 140.031 127.045 141.16 127.045 141.16 Z"
      fill="url(#paint13_linear_4855_160488)"
    />
    <path
      d="M 159.481 57.735 C 159.259 57.744 159.055 57.736 158.86 57.726 C 154.077 57.538 151.823 53.25 151.823 50.018 C 151.823 49.554 151.815 48.789 151.806 47.819 C 151.78 44.939 151.713 40.222 151.653 36.174 C 153.031 44.853 155.133 56.051 159.277 57.65 C 159.345 57.675 159.413 57.701 159.481 57.735 Z"
      fill="#FCCDE0"
    />
    <path
      d="M 174.966 37.454 L 174.966 51.229 C 171.265 56.162 164.918 57.529 160.383 57.744 C 160.068 57.752 159.762 57.744 159.482 57.735 C 159.259 57.744 159.056 57.735 158.861 57.726 C 154.078 57.537 151.823 53.249 151.823 50.018 C 151.823 49.554 151.815 48.789 151.806 47.819 C 152.249 48.128 153.099 48.429 154.172 48.66 C 157.396 49.374 162.68 49.58 165.317 48.231 C 169.053 46.306 173.613 35.417 173.613 35.417 C 173.613 35.417 174.218 36.105 174.966 37.454 Z"
      fill="#002966"
    />
    <path
      d="M 160.91 52.236 C 160.998 52.401 159.668 53.724 158.117 53.667 C 156.564 53.621 155.697 52.224 155.795 52.064 C 155.883 51.878 156.734 52.516 158.11 52.551 C 159.486 52.608 160.833 52.043 160.91 52.236 Z"
      fill="#FDB2D0"
    />
    <path
      d="M 159.482 57.735 C 159.259 57.744 159.056 57.735 158.861 57.726 C 154.078 57.537 151.823 53.249 151.823 50.018 C 151.823 49.554 151.815 48.789 151.806 47.819 C 152.249 48.128 153.099 48.429 154.172 48.66 C 155.422 53.172 157.056 56.79 159.277 57.649 C 159.345 57.675 159.413 57.7 159.482 57.735 Z"
      fill="#003D99"
    />
    <path
      d="M 164.389 59.204 C 163.991 60.021 160.749 59.342 159.634 59.514 C 159.6 58.861 159.591 58.268 159.608 57.735 C 162.288 58.457 164.841 58.294 164.389 59.204 Z"
      fill="#FCCDE0"
    />
  </svg>
)
