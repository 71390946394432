import React from 'react'
import { CallMiniature } from './CallMiniature'

const CallOverlay: React.FC = ({ children }) => (
  <>
    <CallMiniature />
    {children}
  </>
)

export { CallOverlay }
